import React from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import uuid from 'react-uuid';
import { HomepageFooterProps, footerCTA } from './store';
import { useIsMobile } from '~/common/hooks/use-is-mobile';
import ChevronRight from '~/common/components/ui-elements/svg-icon/svgs/ChevronRight';

interface FooterCTAProps extends footerCTA {
  color?: string;
}

interface CTAContentProps {
  bgColor?: string;
  fontColor?: string;
  displayImage?: boolean;
  count: number;
  footerCTAList: footerCTA[];
}

interface ImgContentProps {
  bgColor?: string;
  image?: string;
  displayImage?: boolean;
}

export const HomepageFooterCTASection = (props: HomepageFooterProps) => {
  const { isMobile } = useIsMobile();
  const count = isMobile() ? 2 : 3;
  const displayImage = isMobile()
    ? props.showImageInMobile
    : (props.footerCTALinks?.length < 3 ? true : false) &&
      props.showImageInDesktop;
  const bgColor = `#${props.footerBackgroundColor}` ?? '#f3f3f3';
  const fontColor = `#${props.footerFontColor}` ?? '#323334';
  const mobileCTAs = props.footerCTALinks.filter((x) => x.showInMobile);

  return (
    <>
      {props.showFooter && (
        <div className={cn('full-device-width', styles.FooterContainer)}>
          <div className={styles.FooterContents}>
            <>
              <ImgContent
                bgColor={bgColor}
                image={props.footerImage}
                displayImage={displayImage}
              />

              <CTAContent
                bgColor={bgColor}
                fontColor={fontColor}
                displayImage={displayImage}
                count={count}
                footerCTAList={isMobile() ? mobileCTAs : props.footerCTALinks}
              />
            </>
          </div>
        </div>
      )}
    </>
  );
};

const CTAContent = (props: CTAContentProps) => {
  return (
    <div
      className={cn(styles.FooterContainerCTAs, {
        [styles.center]: !props.displayImage,
      })}
      style={{ backgroundColor: props.bgColor }}
    >
      <div className={styles.FooterContainerCTAsContent}>
        {props.footerCTAList.slice(0, props.count).map((cta, index) => (
          <FooterCTASection key={index} color={props.fontColor} {...cta} />
        ))}
      </div>
    </div>
  );
};

const FooterCTASection = (props: FooterCTAProps) => {
  return (
    <div className={styles.CTAcontainer}>
      <a
        href={props.footerCtalink}
        className={styles.CTALink}
        style={{ color: props.color }}
      >
        <span className={styles.CTALinkContents}>
          <span className={styles.CTALinkText}>{props.footerCtatext}</span>
          <i className={styles.CTALinkIcon}>
            <ChevronRight color={props.color} className={styles.ChevronRight} />
          </i>
        </span>
      </a>
      <p
        className={styles.CTALearnMore}
        style={{ color: props.color }}
        dangerouslySetInnerHTML={{
          __html: props.footerCtalearnMore as string,
        }}
      />
    </div>
  );
};

const ImgContent = (props: ImgContentProps) => {
  return (
    <>
      {props.displayImage && (
        <div
          className={styles.FooterContainerImg}
          style={{ backgroundColor: props.bgColor }}
        >
          <img src={props.image} className={styles.FooterImg} />
        </div>
      )}
    </>
  );
};
